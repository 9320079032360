import './src/styles/layout.scss';
import posthog from 'posthog-js';

export const onClientEntry = () => {
	// Initialize PostHog
	if (typeof window !== 'undefined') {
		// Check for browser environment
		posthog.init(process.env.POSTHOG_API_KEY || 'phc_bWeAk1MrtA8sOkR2Fiqzs3mOuLqDWZNLIsFJlqp7zmX', 
    {
			api_host: process.env.POSTHOG_API_HOST || 'https://us.i.posthog.com',
			loaded: (posthog) => {
				if (window.location.hostname === 'localhost') {
					// Disable capturing on localhost
					posthog.opt_out_capturing();
				}
			},
			capture_pageview: false, // We'll handle this with onRouteUpdate
		});
	}
};

export const onRouteUpdate = ({ location }) => {
	// Capture page views
	if (typeof posthog !== 'undefined') {
		posthog.capture('$pageview');
	}

	// Your existing font loading code
	if (
		location.pathname === '/p/creditkarma/mortgage-rates/' ||
		location.pathname === '/p/creditkarma/mortgage/'
	) {
		// Avoid duplicate font link elements
		if (!document.getElementById('google-font-lato')) {
			const link = document.createElement('link');
			link.id = 'google-font-lato';
			link.href = 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap';
			link.rel = 'stylesheet';
			document.head.appendChild(link);
		}
	} else {
		// Optionally remove the font when navigating away from the page
		const latoLink = document.getElementById('google-font-lato');
		if (latoLink) {
			latoLink.parentNode.removeChild(latoLink);
		}
	}
};
